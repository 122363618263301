//======> Tornado UI Core Base <=======//
import './core/Tornado';
import Tornado from './core/Tornado';


document.addEventListener('DOMContentLoaded', event => {
    "use strict";
    //====> Header in Hero <====//
    Tornado.getElements('.main-header, .moving-element, .tornado-header').forEach(element => {
        Tornado.getElement('.header-included')?.prepend(element);
    });

    //====> Hero Slider <====//
    Tornado.slider('.hero-slider', {
        items      : 1,     //===> Display Slides Count
        duration   : 8000,  //===> Autoplay Duration
        pagination : true,   //===> Show Dots Pagination
        controls   : true,   //===> Show Arrows
        animation  : "gallery",    //===> Animation Mode [carousel, gallery]
        speed      : 1000,           //===> Animation Speed
        uniuqClass : 'hero-slider-outer'
    });

    //====> Carousel Slider <====//
    Tornado.getElements('.carousel-slider').forEach(element => {
        //====> Dynamic Options <====//
        var itemsNumber = element.getAttribute('data-items'),
            itemsSmall  = element.getAttribute('data-sm'),
            itemsMedium = element.getAttribute('data-md'),
            itemsLarge  = element.getAttribute('data-lg'),
            controls    = element.getAttribute('data-controls'),
            cssClass    = element.getAttribute('data-class') || 'carousel-slider-outer',
            pagination  = element.getAttribute('data-pagination');

        //====> Create Slider <====//
        Tornado.slider(element, {
            duration   : 7000,
            speed      : 700,
            items      : parseInt(itemsNumber) || 1,
            pagination : pagination ? true : false,
            controls   : controls ? true : false,
            uniuqClass : cssClass,
            responsive : {
                small  : { items: parseInt(itemsSmall)  || 2},
                medium : { items: parseInt(itemsMedium) || 3},
                large  : { items: parseInt(itemsLarge)  || 4} 
            }
        });
    });

    //====> Gallery Slider <====//
    Tornado.slider('.gallery-slider .theSlider', {
        items      : 1,     //===> Display Slides Count
        duration   : 8000,  //===> Autoplay Duration
        pagination : true,   //===> Show Dots Pagination
        controls   : true,   //===> Show Arrows
        animation  : "gallery",    //===> Animation Mode [carousel, gallery]
        speed      : 1000,           //===> Animation Speed
        navAsThumbnails : true,
        navContainer    : ".gallery-slider .thumbnails",
    });
});